import { React } from 'react'
import { useStore } from 'react-hookstore'
import Toggle from 'react-toggle'
import { GoVerified, GoUnverified } from 'react-icons/go'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import BasicProofStatus from './basic'
import { confirmAlert } from '../../../../shared/components/confirmAlert'
import { TEZID_APPS } from '../../../../shared/config'
import { getNetworkInfo } from '../../../../shared/utils'
import 'react-toggle/style.css'
import './gov.css'

// eslint-disable-next-line
export default (props) => {
  const [network,] = useStore('network')
  const networkInfo = getNetworkInfo(network)

  const handleEnableKYC = async () => {
    if (!props.proof) return
    if (props?.proof?.meta?.kyc) return
    confirmAlert({
      title: 'Enable KYC?',
      messageComponent: (
        <div>
          This will allow us access to your data. This action cannot be undone. 
          Read <a rel="noreferrer" target="_blank" href="https://blog.tezid.net/tezid-v3-0-w-kyc-d29df8fea470">more</a>.
        </div>
      ),
      buttons: [
        {
          label: 'Cancel',
          classes: 'cancel'
        },
        {
          label: 'Yes',
          onClick: async () => { await props.enableKYC() },
          classes: 'submit'
        }
      ],
      closeOnEscape: true
    })
  }

  const handleEnableKYCPlatform = async (platform) => {
    if (!props.proof) return
    if (!props?.proof?.meta?.kyc === 'true') return
    const meta = props?.proof?.meta || {}
    if (meta[`kyc_${platform.id}`] === 'true') return
    confirmAlert({
      title: 'Enable KYC Platform?',
      messageComponent: (
        <div>
          This will allow <a rel="noreferrer" target="_blank" href={platform.url}>{platform.name}</a> access to your data. This action cannot be undone.
          TezID is not responsible for {platform.name}'s handling of your data.
          Read <a rel="noreferrer" target="_blank" href="https://blog.tezid.net/tezid-v3-0-w-kyc-d29df8fea470">more</a>.
        </div>
      ),
      buttons: [
        {
          label: 'Cancel',
          classes: 'cancel'
        },
        {
          label: 'Yes',
          onClick: async () => { await props.enableKYCPlatform(platform.id) },
          classes: 'submit'
        }
      ],
      closeOnEscape: true
    })
  }

  const handleNextPlatform = async (platform) => {
    confirmAlert({
      title: 'Our next KYC Platform?',
      messageComponent: (
        <div>
          Are looking to do KYC on Tezos? Look no further! Reach out via <a href="https://t.me/tezid" target="_blank" rel="noreferrer">telegram</a>, <a href="https://twitter.com/tezid_net" target="_blank" rel="noreferrer">twitter</a> or <a href="mailto:support@tezid.net">email</a> and sign up as a TezID KYC Platform today!
        </div>
      ),
      buttons: [
        {
          label: 'OK',
          classes: 'submit'
        }
      ],
      closeOnEscape: true
    })
  }

  const platformItems = TEZID_APPS.filter(a => a.kyc || (a.kyc_testnet && networkInfo.testnet)).map(p => {
    const enabled = (props?.proof?.meta || {})[`kyc_${p.id}`] === 'true'
    return (
      <div key={p.id} className={`${enabled ? 'enabled' : 'disabled'} platformItem`} onClick={() => handleEnableKYCPlatform(p)}>
        { enabled &&
          <div className="mark">
            <GoVerified />
          </div>
        }
        { !enabled &&
          <div className="mark">
            <GoUnverified />
          </div>
        }
        <img alt={p.id} src={p.icon} />
      </div>
    )
  })

  return (
    <BasicProofStatus {...props}>
      { props.proof &&
      <>
      <div className="statusElement">
        <label>KYC</label>
        <div className="value">
          <Toggle
            onClick={handleEnableKYC}
            checked={props?.proof?.meta?.kyc === 'true'}
            onChange={()=>{}}
            className="KYCToggle"
            icons={false}
          />
        </div>
      </div>
      <div className="statusElement">
        <div className="platforms">
          {platformItems}
          <div className="disabled platformItem nextPlatform" onClick={handleNextPlatform}>
            <AiOutlineQuestionCircle size="2.8em" />
          </div>
        </div>
      </div>
      </>
      }
    </BasicProofStatus>
  )
}
