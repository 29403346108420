import { formatDate } from '../../utils'
import ChainBornLogo from './chainborn-logo-small.webp'
import './index.css'

export const Over18 = ({ tezid, local, style, onClick }) => {
  return (
    <div className="icard over18" style={style} onClick={onClick}>
      <div className="top">
        <div className="label">{local?.label}</div>
        <div className="sublabel">Powered by <a href={local?.issuerLink} target="_black" rel="noreferrer">{local?.issuer}</a></div>
      </div>
      <div className="center">
        <div className="sublabel">{local.sublabel}</div>
      </div>
      <div className="bottom">
        <div className="issued">
          <div className="midlabel">Issued on</div>
          <div className="sublabel">{formatDate(tezid?.register_date)}</div>
        </div>
        <div className="expiration">
          <div className="midlabel">Expiration date</div>
          <div className="sublabel">{formatDate(tezid?.register_date, { addDays: 365 })}</div>
        </div>
      </div>
      <input type="checkbox" checked={tezid?.verified} disabled />
    </div>
  )
}

export const ChainBorn = ({ tezid, local, style, onClick }) => {
  return (
    <div className="icard chainborn" style={style} onClick={onClick}>
      <div className="top">
        <img src={ChainBornLogo} alt="chainborn-logo" />
        <div className="sublabel">Powered by <a href={local?.issuerLink} target="_black" rel="noreferrer">{local?.issuer}</a></div>
      </div>
      <div className="center"></div>
      <div className="bottom">
        <div className="label">{local.label}</div>
        <div className="sublabel">{local.sublabel}</div>
      </div>
      <input type="checkbox" checked={tezid?.verified} disabled />
    </div>
  )
}

export const Defi = ({ tezid, local, style, onClick }) => {
  return (
    <div className="icard defi" style={style} onClick={onClick}>
      <div className="top">
        <div className="label">{local?.label}</div>
        <div className="sublabel">Powered by <a href={local?.issuerLink} target="_black" rel="noreferrer">{local?.issuer}</a></div>
      </div>
      <div className="center">
        <div className="sublabel">{local.sublabel}</div>
      </div>
      <div className="bottom">
        <div className="issued">
          <div className="midlabel">Issued on</div>
          <div className="sublabel">{formatDate(tezid?.register_date)}</div>
        </div>
        <div className="expiration">
          <div className="midlabel">Expiration date</div>
          <div className="sublabel">{formatDate(tezid?.register_date, { addDays: 365 })}</div>
        </div>
      </div>
      <input type="checkbox" checked={tezid?.verified} disabled />
    </div>
  )
}
