import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from './shared/apollo'
import App from './app'

if (window.location.hostname !== 'localhost')
  Sentry.init({dsn: "https://cbdcd59c5c874819a71dc06ffb645e35@sentry.asbjornenge.com/2"})

const client = getApolloClient()

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)
