export default function gov() {
  return (
<svg width="730" height="730" viewBox="0 0 730 730" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="730" height="730" />
<path d="M234.219 290.056C235.187 294.651 237.473 298.846 241.578 301.359C242.312 326.98 240.911 352.6 241.728 378.203C249.671 378.486 257.63 378.37 265.59 378.27C265.506 352.766 265.673 327.279 265.49 301.792C269.277 298.879 272.297 295.1 272.898 290.222C278.505 290.056 284.128 290.089 289.735 290.339C289.551 306.886 289.768 323.434 289.651 339.981C289.635 348.321 289.701 356.662 289.751 365.019C289.735 367.982 289.735 370.978 289.701 373.975C289.685 374.458 289.651 375.423 289.635 375.906L289.534 378.353C297.51 378.37 305.486 378.42 313.479 378.32C313.396 348.921 313.479 319.522 313.429 290.139C317.451 290.156 321.455 290.122 325.477 290.122C329.498 290.122 333.503 290.139 337.524 290.139C337.474 319.522 337.557 348.921 337.474 378.32C345.467 378.42 353.443 378.37 361.419 378.353L361.319 375.906C361.302 375.423 361.269 374.458 361.252 373.975C361.219 370.978 361.219 367.982 361.202 365.019C361.252 356.662 361.319 348.321 361.302 339.981C361.185 323.434 361.402 306.886 361.219 290.339C366.825 290.089 372.448 290.056 378.055 290.222C378.656 295.1 381.676 298.879 385.464 301.792C385.28 327.279 385.447 352.766 385.364 378.27C393.323 378.37 401.282 378.486 409.225 378.203C410.043 352.6 408.641 326.98 409.375 301.359C413.48 298.846 415.766 294.651 416.734 290.056C422.424 290.089 429.432 291.388 433.604 286.46C439.444 280.683 436.774 269.53 428.848 267.166C421.022 265.335 412.762 267.665 404.97 265.951C378.405 250.585 352.191 234.604 325.493 219.471C325.493 219.471 325.477 219.471 325.477 219.488C325.477 219.488 325.46 219.488 325.46 219.471C298.762 234.604 272.548 250.585 245.983 265.951C238.191 267.665 229.931 265.335 222.105 267.166C214.179 269.53 211.509 280.683 217.35 286.46C221.521 291.388 228.529 290.089 234.219 290.056Z" fill="#ff834f" />
<path d="M429.015 403.158C427.613 402.608 426.078 402.409 424.559 402.409C372.064 402.658 319.569 402.442 267.091 402.525C253.392 402.908 239.609 401.893 225.926 402.792C221.554 403.075 217.483 405.422 215.364 409.251C214.663 410.516 214.212 411.881 214.129 413.396C213.378 420.404 219.953 426.83 226.944 426.347C292.655 426.364 358.348 426.364 424.059 426.347C430.483 426.847 436.84 421.586 436.974 415.027C437.308 409.817 433.754 405.039 429.015 403.158Z" fill="#ff834f"/>
<path d="M464.04 627.95H187.159C168.83 627.95 154 613.107 154 594.763V211.643C154 193.299 168.83 178.456 187.159 178.456H464.04C482.369 178.456 497.199 193.299 497.199 211.643V594.763C497.339 613.107 482.369 627.95 464.04 627.95ZM187.159 195.259C178.204 195.259 170.929 202.541 170.929 211.503V594.623C170.929 603.585 178.204 610.867 187.159 610.867H464.04C472.995 610.867 480.27 603.585 480.27 594.623V211.643C480.27 202.681 472.995 195.399 464.04 195.399H187.159V195.259Z" fill="#ff834f"/>
<path d="M444.572 483.02H206.585C201.968 483.02 198.051 479.239 198.051 474.478C198.051 469.857 201.828 465.936 206.585 465.936H444.572C449.189 465.936 453.107 469.717 453.107 474.478C453.107 479.239 449.329 483.02 444.572 483.02Z" fill="#ff834f"/>
<path d="M444.594 528.95H206.607C201.99 528.95 198.072 525.169 198.072 520.408C198.072 515.788 201.85 511.867 206.607 511.867H444.594C449.211 511.867 453.128 515.647 453.128 520.408C453.128 525.169 449.351 528.95 444.594 528.95Z" fill="#ff834f"/>
<path d="M444.594 574.88H206.607C201.99 574.88 198.072 571.099 198.072 566.338C198.072 561.717 201.85 557.796 206.607 557.796H444.594C449.211 557.796 453.128 561.577 453.128 566.338C453.128 571.099 449.351 574.88 444.594 574.88Z" fill="#ff834f"/>
<path d="M542.671 551.635H528.12C523.503 551.635 519.585 547.854 519.585 543.093V173.415C519.585 164.453 512.31 157.172 503.356 157.172H241.025C236.408 157.172 232.49 153.391 232.49 148.63V135.187C232.63 116.843 247.461 102 265.789 102H542.671C560.999 102 575.829 116.843 575.829 135.187V518.308C575.829 536.652 560.999 551.635 542.671 551.635ZM536.515 534.691H542.531C551.485 534.691 558.76 527.41 558.76 518.448V135.187C558.76 126.225 551.485 118.944 542.531 118.944H265.789C256.835 118.944 249.559 126.225 249.559 135.187V140.088H503.356C521.684 140.088 536.515 154.931 536.515 173.275V534.691Z" fill="#ff834f"/>
<path d="M503.356 589.722H488.806C484.189 589.722 480.271 585.942 480.271 581.181V211.643C480.271 202.681 472.996 195.399 464.041 195.399H201.71C197.093 195.399 193.176 191.619 193.176 186.858V173.415C193.176 155.071 208.006 140.228 226.334 140.228H503.216C521.544 140.228 536.375 155.071 536.375 173.415V556.535C536.515 574.879 521.684 589.722 503.356 589.722ZM497.34 572.779H503.356C512.31 572.779 519.586 565.497 519.586 556.535V173.415C519.586 164.453 512.31 157.171 503.356 157.171H226.474C217.52 157.171 210.245 164.453 210.245 173.415V178.316H464.041C482.37 178.316 497.2 193.159 497.2 211.503V572.779H497.34Z" fill="#ff834f" />
</svg>
)}
