import { useState, useEffect, useRef } from 'react'
import { BsCardImage, BsPencil } from 'react-icons/bs'
import { useStore } from 'react-hookstore'
import QRCode from 'react-qr-code'
import { validateAddress } from '@taquito/utils'
import ProofDetails from './components/ProofDetails'
import {
  signAvatarMessage,
  resolveTezosDomainsAddress,
} from '../../shared/wallet'
import { 
  pinBlob,
  getProofs,
  getAvatar,
  setAvatar as setAvatarApi 
} from '../../shared/api'
import { 
  CARDS,
  TEZID_PROOFTYPES 
} from '../../shared/config'
import { 
  getIpfsLink, 
  isProofExpired,
  getNetworkInfo,
  getProofTypeCost
} from '../../shared/utils'
import Header from '../../shared/components/header'
import Footer from '../../shared/components/footer'
import logo from '../../graphics/logo-notext.svg'
import './index.css'

/* 
IDEA: 
--
  * Fullscreen? Only show the profile on mobile?
  * QR CODE!? For different proofs? Like tickets? 
  * Qualifications
    * Am I qualified for Versum? What am I missing?
    * Am I qualified to receive notifications from Objkt?

TODO:
--
*/

export default function Profile(props) {
  const [config,] = useStore('config')
  const [wallet,] = useStore('wallet')
  const [network,] = useStore('network')
  const [,setGlobalAvatar] = useStore('avatar')
  const [notificationchannels, setNotificationChannels] = useStore('notificationchannels')
  const avatarFile = useRef(null)
  const [name, setName] = useState('')
  const [edit, setEdit] = useState(false)
  const [selectedProof, setSelectedProof] = useState(null)
  const [domain, setDomain] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [proofs, setProofs] = useState([]) 
  const [editAvatar, setEditAvatar] = useState(null)
  const [description, setDescription] = useState('')
  const [selectedCard, setSelectedCard] = useState('')

  let proof = proofs.filter(p => p.id === selectedProof)[0]
//  if (!proof && selectedProof) {
//    let pt = Object.values(TEZID_PROOFTYPES).filter(p => p.id === selectedProof)[0]
//    proof = {}
//    proof['id'] = pt.id
//  }
//  console.log(proof)
  const cardIds = Object.keys(CARDS)
  const proofIds = proofs.map(p => p.id)
  const cardProofs = [].concat(proofs)
  Object.values(CARDS).forEach(c => {
    if (c.showIfMissing) {
      if (proofIds.indexOf(c.id) < 0) {
        cardProofs.push({
          id: c.id,
          verified: false
        })
      }
    }
  })
  const cards = cardProofs.filter(p => cardIds.indexOf(p.id) >= 0).map((p, index) => {
    let c = CARDS[p.id]
    let style = {
      'top': 10+index*50,
      'zIndex': 10+index+1
    }
    let selected = selectedCard === p.id
    if (selected) style['zIndex'] = 100
    let comp = <c.component key={p.id} tezid={p} local={c} style={style} onClick={() => handleSetSelectedCard(p.id)} />
    return comp
  })
  const proofType = Object.values(TEZID_PROOFTYPES).filter(p => p.id === selectedProof)[0]
  const networkInfo = getNetworkInfo(network) 
  const isMe = (props.addr === wallet?.address)
  const validAddress = validateAddress(props.addr)

  useEffect(() => {
    (async () => {
      if (!validAddress) return
      if (!isMe) setSelectedProof(null)
      try {
        const proofs = await getProofs(props.addr)
        const avatar = await getAvatar(props.addr)
        setProofs(proofs)
        setAvatar(avatar)
      } catch(e) {
        setProofs([])
        setAvatar(null)
        console.error('Unable to get Proofs for address')
      }
      try {
        const tezosDomain = await resolveTezosDomainsAddress(config?.network, props.addr)
        setDomain(tezosDomain)
      } catch(e) {
        setDomain(null)
        console.error('Unable to get Tezos Domain for address')
      }
    })()
    let _refetchProofs = async () => {
      if (!validateAddress(props.addr)) return
      const proofs = await getProofs(props.addr)
      setProofs(proofs)
    }
    const interval = setInterval(_refetchProofs, 4000) // Set back to two?
    return () => clearInterval(interval)
  },[props.addr, config?.network, validAddress, isMe])

  if (!validAddress) return (
    <div className="Profile">
      <Header />
      <div className="container">
        <div className="card">
          <div className="noWallet">
            <h3>Connect Wallet to set up your TezID!</h3>
          </div>
          <div className="cardFooter">
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) 

  const handleSetSelectedCard = async (id) => {
    if (selectedCard === id) setSelectedCard(null)
    else setSelectedCard(id)
  }

  const handleToggleProofDetails = (_proof) => {
    if (!wallet) return
    if (!isMe) return
    let _selectedProof = _proof.id
    if (selectedProof === _selectedProof) _selectedProof = null
    setSelectedProof(_selectedProof)
  }

  const handleSetEdit = () => {
    setEdit(true)
    setName(avatar?.name || '')
    setEditAvatar(avatar?.avatar)
    setDescription(avatar?.description || '')
  }
  const handleUpdateAvatar = async () => {
    const signature = await signAvatarMessage({ address: wallet.address })
    signature.pk = wallet?.publicKey
    const payload = {
      name: name,
      avatar: editAvatar,
      description: description,
      signature: signature
    }
    const _avatar = await setAvatarApi(wallet?.address, payload)
    setAvatar(_avatar)
    setGlobalAvatar(_avatar)
    setEdit(false)
  }

  const handleAvatarImageUpdate = async (e) => {
    if (!edit) return
    const file = e.target.files[0]
    if (!file) return
    const pin = await pinBlob(file, file.name)
    setEditAvatar(`ipfs://${pin.Hash}`)
  }

  const handleRefetchStorage = async () => {
    const _proofs = await getProofs(wallet?.address)
    setProofs(_proofs)
  }

  const proofListItems = (config?.prooftypes || Object.keys(TEZID_PROOFTYPES))
    .map(ptype => {
      const p = Object.assign({}, TEZID_PROOFTYPES[ptype])
      p.proof = proofs.filter(__p => __p.id === p.id)[0]
      if (p?.proof) p.proof.expired = isProofExpired(p.proof)
      let status = 'unregistered'
      if (p?.proof) status = 'registered' 
      if (p?.proof?.verified) status = 'verified' 
      if (p?.proof?.expired) status = 'expired'
      return (
        <div 
          key={p.id} 
          className={`${p.id} proofListItem ${p?.proof?.verified ? 'verified' : ''} ${proof?.id === p.id ? 'selected' : ''} ${status}`}
          onClick={() => handleToggleProofDetails(p)}
        >
          {p.icon}
          <div className="proofLabel">{p.label}</div>
        </div>
      )
    })

  let avatarStyle = {}
  if (avatar?.avatar) avatarStyle = {
    backgroundImage: `url("${getIpfsLink(avatar.avatar)}")`
  }
  if (edit && editAvatar) avatarStyle = {
    backgroundImage: `url("${getIpfsLink(editAvatar)}")`
  }

  return (
    <div className="Profile">
      <Header />
      <div className="container">
        <div className="card">
          { isMe &&
          <div className="avatarEdit"> 
            { !edit &&
              <BsPencil onClick={handleSetEdit} />
            }
            { edit &&
              <>
              <button className="submit" onClick={handleUpdateAvatar}>Save</button>
              <button className="cancel" onClick={() => setEdit(false)}>Cancel</button>
              </>
            }
          </div>
          }
          <div className="top">
            <div className={`avatar ${edit ? 'edit' : ''}`} style={avatarStyle} onClick={() => edit && avatarFile.current.click()}>
              { !avatar?.avatar &&
                <BsCardImage />
              }
              <input type="file" ref={avatarFile} onChange={handleAvatarImageUpdate} style={{ display: 'none' }} />
            </div>
            <div className="textual">
              { !edit &&
                <>
                <div className="name">{avatar?.name || '-'}</div>
                <div className="description">{avatar?.description || '-'}</div>
                </>
              }
              { edit &&
                <>
                <input type="text" className="name" value={name} onChange={e => setName(e.target.value)} />
                <textarea className="description" value={description} onChange={e => setDescription(e.target.value)} />
                </>
              }
            </div>
          </div>
          <div className="tezosnames">
            <div className="address">
              <a href={`${config?.tzkt_uri}/${props.addr}`} target="_blank" rel="noreferrer">{props.addr}</a>
            </div>
            { domain &&
            <div className="domain">
              <a href={`https://app.tezos.domains/domain/${domain}`} target="_blank" rel="noreferrer">{domain}</a>
            </div>
            }
          </div>
          <div className="proofList">
            {proofListItems}
          </div>
          <div className={`proofDetails ${!proofType ? 'hidden' : ''}`}>
            { proofType != null &&
              <ProofDetails 
                key={proofType.id}
                cost={getProofTypeCost(config?.cost, proofType.id)}
                proof={proof}
                config={config}
                wallet={wallet}
                isMine={wallet?.address === props.addr}
                cancel={() => setSelectedProof(null)}
                proofType={proofType} 
                networkInfo={networkInfo}
                hasAnyProofs={true}
                handleRefetchStorage={handleRefetchStorage}
                notificationchannels={notificationchannels}
                setNotificationChannels={setNotificationChannels}
              />
            }
          </div>
          <div className="qrcode">
            <QRCode value={`https://tezid.net/api/${config?.network}/getproofs/${props.addr}`} size={200} fgColor="#ff834f" />
          </div>
          <div className="cardFooter">
            <img src={logo} alt="logo" />
          </div>
          <div className="icards">
            {cards}
          </div>
        </div>
        <div className="disclaimer">By using our service you accept our <a target="_blank" rel="noreferrer" href="terms.pdf">terms</a> and <a target="_blank" rel="noreferrer" href="privacy-policy.pdf">privacy policy</a>.</div>
      </div>
      <Footer />
    </div>
  )
}
