import { 
  React, 
  useEffect 
} from 'react'
import { 
  capitalize,
  getProofStatus,
} from '../../../../shared/utils'
import WorldCoinIcon from '../../../../shared/graphics/worldcoin'
import './worldid.css'

// eslint-disable-next-line
export default (props) => {
  let proofStatus = getProofStatus(props.proof)
  if (proofStatus !== 'Verified' && props.serverProcessingStatus !== '')
    proofStatus = capitalize(props.serverProcessingStatus) 

  useEffect(() => {
    props.setRequirementText('Open then link below. Scan the QR code on the next page. Sign in via your Worldcoin app. Then return here.') 
  }, [props])

  const worldCoinLink = `https://id.worldcoin.org/login?response_type=id_token&client_id=${props?.config?.worldcoin_app_id}&redirect_uri=https://tezid.net&nonce=${props?.wallet?.address}&ready=true&scope=openid`

  return (
    <>
    <div className="status resourceCollectorWorldID">
      <a target='_blank' rel='noreferrer' href={worldCoinLink}>
        <WorldCoinIcon />
        Connect WorldID
      </a>
    </div>
    <div className="buttons resourceCollectorWorldID">
      <button className="cancel" onClick={props.cancel}>Back</button>
      <div className="statusElement">
        <label></label>
        <div className={`${props.serverProcessingStatus} value`}>{proofStatus}</div>
      </div>
    </div>
    </>
  )
}
