import Header from '../../shared/components/header'
import Footer from '../../shared/components/footer'
import { TEZID_APPS } from '../../shared/config'
import './index.css'

export default function Apps(props) {
  const handleAppTap = (app) => {
    window.open(app.url, '_blank')
  }

  const appListItems = TEZID_APPS.filter(app => app.applist).map(app => {
    return (
      <div key={app.name} className="app" style={{ border: `1px solid ${app.borderColor}` }} onClick={() => handleAppTap(app)}>
        <div className="top">
          <div className="icon">
            <img src={app.icon} alt={app.name} />
          </div>
          <div className="info">
            <div className="name" style={{ color: app.color }}>{app.name}</div>
            <div className="tagline">{app.tagline}</div>
          </div>
        </div>
        <div className="bottom" style={{ backgroundColor: app.descriptionBackgroundColor, color: app.descriptionColor }}>
          {app.description} 
        </div>
      </div>
    )
  })

  return (
    <div className="Apps">
      <Header />
      <div className="container">
        {appListItems}
      </div>
      <Footer />
    </div>
  )
}
