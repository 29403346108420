import { React } from 'react'
import { clearLocalStorage } from '../../shared/state'
import { 
  TempleError,
} from '../../shared/utils'
import './index.css'

// eslint-disable-next-line
export default (props) => {
  const handleClearLocalStorage = () => {
    clearLocalStorage()
    window.location.reload()
  }

  let suggestions = (
    <div className="suggestion">
      Perhaps <span className="action" onClick={handleClearLocalStorage}>clearing your localstorage</span> will help?
    </div>
  )
  if (props.error instanceof TempleError)
    suggestions = (
      <div>
        <div className="suggestion">
          You need to install and connect <a href="https://templewallet.com/" target="_blank" rel="noreferrer">Temple Wallet</a> in order to use Tezid. 
        </div>
        <div className="suggestion">
          Perhaps <span className="action" onClick={handleClearLocalStorage}>clearing your localstorage</span> will help?
        </div>
      </div>
    )

  return (
    <div className="Error">
      <h1>Ops, an error occurred!</h1>
      <code>{props.error.message}</code>
      <div className="suggestions">
        {suggestions}
      </div>
    </div>
  )
}
