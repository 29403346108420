import { useRef } from 'react'
import { 
  AiOutlineApi,
  AiOutlineUser,
  AiOutlineBell, 
  AiOutlineAudit,
  AiOutlineRobot,
  AiOutlineTwitter
} from 'react-icons/ai'
import Header from '../../shared/components/header'
import Footer from '../../shared/components/footer'
import logo from '../../graphics/logo-notext.svg'
import ProfileThumb from '../../graphics/profile-thumbnail.jpg'
import EmailThumb from '../../graphics/email-thumbnail.jpg'
import './index.css'

export default function Promo(props) {
  const apiSection = useRef(null)
  const kycSection = useRef(null)
  const oracleSection = useRef(null)
  const profileSection = useRef(null)
  const twitterSection = useRef(null)
  const notificationSection = useRef(null)
  return (
    <div className="Promo">
      <Header />
      <div className="container">
        <div className="splash">
          <div className="label">
            <h1>Secure<br/>on-chain identity</h1>
            <div className="icons">
              <AiOutlineRobot onClick={() => oracleSection?.current?.scrollIntoView()} />
              <AiOutlineAudit onClick={() => kycSection?.current?.scrollIntoView()} />
              <AiOutlineUser onClick={() =>  profileSection?.current?.scrollIntoView()} />
              <AiOutlineTwitter TonClick={() =>  twitterSection?.current?.scrollIntoView()} />
              <AiOutlineBell onClick={() =>  notificationSection?.current?.scrollIntoView()} />
              <AiOutlineApi onClick={() =>   apiSection?.current?.scrollIntoView()} />
            </div>
          </div>
          <img src={logo} alt="logo" />
        </div>
        <div className="tutorials">
          <div className="tutorialContainer">
            <div className="videoContainer">
              <a href="https://www.youtube.com/watch?v=lME7sONx02A" target="_blank" rel="noreferrer">
                <img src={ProfileThumb} alt="Profile Tutorial" />
              </a>
            </div> 
            <div className="videoTitle">
              Setting up a TezID Profile
            </div>
          </div>
          <div className="tutorialContainer">
            <div className="videoContainer">
              <a href="https://www.youtube.com/watch?v=VRItis3jJyM" target="_blank" rel="noreferrer">
                <img src={EmailThumb} alt="Email Tutorial" />
              </a>
            </div> 
            <div className="videoTitle">
              Register & Verify a TezID Email Proof
            </div>
          </div>
        </div>
      </div>
      <div className="promocontainer">
      <div ref={oracleSection} className="promosection oracle">
        <div className="top">
          <AiOutlineRobot />
          <h1>Oracle</h1>
        </div>
        <p>
          TezID is an identity oracle for Tezos. The function of an oracle in a blockchain context is to provide off-chain / real-world information to the blockchain.
          TezID provides identity information tied to a wallet address, in a very secure and user-controlled manner.
        </p>
        <a href="https://blog.tezid.net/tezid-9d82efbba006" target="_blank" rel="noreferrer">Read more...</a>
      </div>
      <div ref={kycSection} className="promosection kyc">
        <div className="top">
          <AiOutlineAudit />
          <h1>Defi Compliance</h1>
        </div>
        <p>
          Via our collaboration with <a href="https://altme.io" target="_blank" rel="noreferrer">Altme</a> we are happy to support their "Defi Compliance" proof. The proof is SSI via the Altme wallet app, and available on-chain via TezID. You can reuse this proof across multiple Tezos dapps and services.
        </p>
        <a href="https://blog.tezid.net/tezid-altme-37db77809118" target="_blank" rel="noreferrer">Read more...</a>
      </div>
      <div ref={profileSection} className="promosection profile">
        <div className="top">
          <AiOutlineUser />
          <h1>Profile</h1>
        </div>
        <p>
          TezID provides user profiles. Users can choose an avatar and set a name for their wallet address. You dapp can fetch the information freely from our RESTful API. We also provide a <b>ChainPass</b>, a blockchain profile card, containing a QR code for easy verification and access. 
        </p>
        <a href="https://tezid.net/#/tz1UZZnrre9H7KzAufFVm7ubuJh5cCfjGwam" target="_blank" rel="noreferrer">See a ChainPass...</a>
      </div>
      <div ref={twitterSection} className="promosection twitter">
        <div className="top">
          <AiOutlineTwitter />
          <h1>Twitter</h1>
        </div>
        <p>
          TezID provides twitter proofs. This allows users to prove they control a certain twitter @handle and associate this with their Tezos address. Keep in mind that the address {`<->`} handle information is available on-chain in cleartext. 
        </p>
        <a href="https://tezid.net/#/tz1UZZnrre9H7KzAufFVm7ubuJh5cCfjGwam" target="_blank" rel="noreferrer">See a ChainPass...</a>
      </div>
      <div ref={notificationSection} className="promosection notificaitons">
        <div className="top">
          <AiOutlineBell />
          <h1>Notifications</h1>
        </div>
        <p>
          TezID already hold proofs for email and mobile numbers attached to an address. We have a feature called <b>"notification channels"</b> where users can opt-in to receive notifications via email and/or phone from platforms using TezID. Web3 services and platforms can notify the owner of a wallet address via our notificaions API.
        </p>
        <a href="https://blog.tezid.net/notification-channels-41291b61e991" target="_blank" rel="noreferrer">Read more...</a>
      </div>
      <div ref={apiSection} className="promosection api">
        <div className="top">
          <AiOutlineApi />
          <h1>Easy Integration</h1>
        </div>
        <p>
          TezID provide CORS enabled RESTful APIs for easy off-chain integration. We also provide smart contract `views` and `entrypoints` for easy on-chain integration. All of our contracts are open-source! To get started, check out our <a href="https://github.com/tezid" target="blank" rel="noreferrer">GitHub</a> and...
        </p>
        <a href="https://t.me/tezid" target="_blank" rel="noreferrer">Join us @ Telegram!</a>
      </div>
      </div>
      <div className="bloglink">For more information about TezID check out our <a href="https://blog.tezid.net" target="_blank" rel="noreferrer">blog</a>.</div> 
      <Footer />
    </div>
  )
}
