import { 
  React, 
  useEffect 
} from 'react'
import { 
  capitalize,
  getProofStatus,
} from '../../../../shared/utils'
import QRCode from 'react-qr-code'
import './gov.css'

/* 
  NOTE:
    * Get template-id from API config 
    * Why is this QR different than the one on the Profile? (different module?)
*/

// eslint-disable-next-line
export default (props) => {
  let proofStatus = getProofStatus(props.proof)
  if (proofStatus !== 'Verified' && props.serverProcessingStatus !== '')
    proofStatus = capitalize(props.serverProcessingStatus) 

  useEffect(() => {
    props.setRequirementText('We integrate with Persona to verify your document. Scan the below QR code or click the link below on a mobile phone to continue.') 
  }, [props])

  const personaLink = `https://withpersona.com/verify?inquiry-template-id=${props?.config?.PERSONA_INQUIRY_TEMPLATE_ID}&environment-id=${props?.config?.PERSONA_ENVIRONMENT_ID}&reference-id=${props?.wallet?.address}`

  return (
    <>
    <div className="status resourceCollectorGov">
      <QRCode value={personaLink} />
      <a target='_blank' rel='noreferrer' href={personaLink}>I'm on a mobile device</a>
    </div>
    <div className="buttons resourceCollectorGov">
      <button className="cancel" onClick={props.cancel}>Back</button>
      <div className="statusElement">
        <label></label>
        <div className={`${props.serverProcessingStatus} value`}>{proofStatus}</div>
      </div>
    </div>
    </>
  )
}
