import { React, useState } from 'react'
import { useStore } from 'react-hookstore'
import formatNumber from 'format-number'
import { BiLinkExternal } from 'react-icons/bi'
import { useQuery } from '@apollo/client'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { 
  QUERY_TRADES, 
  QUERY_EXCHANGE,
} from '../../shared/apollo' 
import {
  verifyStake,
  getNetworkInfo,
} from '../../shared/utils' 
import {
  stake,
  unstake 
} from '../../shared/wallet' 
import Header from '../../shared/components/header'
import Footer from '../../shared/components/footer'
import './index.css'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
)

const _lt = new Date()
const _gt = new Date()
_gt.setDate(_gt.getDate()-30)
const formatBalance = formatNumber({ round: 4 })
const formatPercent = formatNumber({ round: 2 })

export default function Idz(props) {
  const [idz, setIdz] = useStore('idz')
  const [wallet] = useStore('wallet')
  const [network] = useStore('network')
  const [loading, setLoading] = useState(false)
  const [stakeValue, setStakeValue] = useState(0)
  const networkInfo = getNetworkInfo(network)
  const exchange = useQuery(QUERY_EXCHANGE, { variables: { v1: { address: { _eq: networkInfo.idzdex_contract }}} })
  const trades = useQuery(QUERY_TRADES, {
    variables: {
      v1: {
        bucket: {
          _gt: _gt, 
          _lt: _lt 
        },
        exchangeId: {
          _eq: networkInfo.idzdex_contract 
        }
      },
      v2: {
        bucket: "asc"
      }
    }
  })
  let _data = {}
  if (trades.data) {
    let labels = trades.data.quotes1d.filter(q => q.average != null).map(q => q.bucket.split('T')[0])
    let __data = trades.data.quotes1d.filter(q => q.average != null).map(q => q.average)
    _data = {
      labels,
      datasets: [
        {
          label: 'XTZ/IDZ',
          data: __data, 
          borderColor: 'rgb(255, 131, 79)',
          backgroundColor: 'rgba(255, 131, 79, 0.5)',
          fill: true
        }
      ]
    }
  }
  let exchangeRate = 0
  if (exchange?.data?.exchange.length > 0) {
    exchangeRate = parseFloat(exchange.data.exchange[0].midPrice)
  }
  const can_stake = (idz.idzdex > 0 && stakeValue > 0 && stakeValue <= idz.idzdex)
  const can_unstake = (idz.xidz > 0 && stakeValue > 0 && stakeValue <= idz.xidz)

  const handleStake = async () => {
    if (loading) return
    if (!can_stake) return
    if (!wallet?.address) return
    try {
      setLoading(true)
      await stake(networkInfo, wallet.address, stakeValue)
      const target_xidz = idz.xidz + stakeValue
      const target_idzdex = idz.idzdex - stakeValue
      const verifier = (_idz) => {
        if (_idz.xidz === target_xidz && _idz.idzdex === target_idzdex) {
          setIdz(_idz)
          return true
        }
        return false
      } 
      const verified = await verifyStake(wallet.address, verifier)
      if (!verified) throw new Error('Unable to verify operation')
      setStakeValue(0)
      setLoading(false)
    } catch(e) {
      console.error(e)
      setLoading(false)
    }
  }

  const handleUnstake = async () => {
    if (loading) return
    if (!can_unstake) return
    if (!wallet?.address) return
    try {
      setLoading(true)
      await unstake(networkInfo, wallet.address, stakeValue)
      const target_xidz = idz.xidz - stakeValue
      const target_idzdex = idz.idzdex + stakeValue
      const verifier = (_idz) => {
        if (_idz.xidz === target_xidz && _idz.idzdex === target_idzdex) {
          setIdz(_idz)
          return true
        }
        return false
      } 
      const verified = await verifyStake(wallet.address, verifier)
      if (!verified) throw new Error('Unable to verify operation')
      setStakeValue(0)
      setLoading(false)
    } catch(e) {
      console.error(e)
      setLoading(false)
    }
  }

  const xidz_idz_value = (idz?.farm?.tokenValue || 0) * idz.xidz / 100
  //const xidz_xtz_value = xidz_idz_value * exchangeRate 
  let idz_stake_cost = (idz?.farm?.tokenValue || 0) * stakeValue / 100
  if (!can_stake) idz_stake_cost = 0
  const total_staked = (idz?.farm?.totalStaked || 0) / 10**6
  const staked_percent = formatPercent(((idz?.xidz || 0) / total_staked) * 100) 

  return (
    <div className="Idz">
      <Header />
      <div className="container">
        <div className="quipu">
          <div className="quipuIcons">
            <img className="idzIcon" alt="idz" src="idz.png" />
            <img className="quipuIcon" alt="quipu" src="quipu.png" />
          </div>
          <div className="quipuLinks">
            <div>
              <a target="_blank" rel="noreferrer" href="https://quipuswap.com/swap/tez-KT1WapdVeFqhCfqwdHWwTzSTX7yXoHgiPRPU_0">
                <div>Buy</div>
                <BiLinkExternal />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://quipuswap.com/liquidity/add/tez-KT1WapdVeFqhCfqwdHWwTzSTX7yXoHgiPRPU_0">
                <div>Invest</div>
                <BiLinkExternal />
              </a>
            </div>
          </div>
        </div>
        <div className="readlink">Read about $IDZ <a target="_blank" rel="noreferrer" href="https://blog.tezid.net/idz-9f233a127e4c">here.</a></div>
        <h3 className="myHeading">$IDZ</h3>
        <div className="my">
          <div className="element">
            <div className="label">XTZ/IDZ</div>
            <div className="value">{formatBalance(exchangeRate)}</div>
          </div>
          <div className="element">
            <div className="label">Balance</div>
            <div className="value">{formatBalance(idz.idz)}</div>
          </div>
          <div className="element">
            <div className="label">Value (XTZ)</div>
            <div className="value">{formatBalance(idz.idz * exchangeRate)}</div>
          </div>
        </div>
        <div className="chart">
          { Object.keys(_data).length > 0 && networkInfo.idzdex_contract &&
            <Line options={{
              responsive: true,
              scales:{
                x: {
                  display: false,
                },
                y: {
                  display: false,
                  grid: {
                    display: false
                  }
                }
              },
              plugins: {
                legend: {
                  display: false,
                }
              }
            }} data={_data} />
          }
        </div>
        <h3 className="xidzHeading">$xIDZ</h3>
        <div className="xidzWrapper">
          { loading &&
          <div className="loader">
            <img alt="idz" src="idz.png" />
          </div>
          }
          <div className={`xidz ${loading ? 'loading': ''}`}>
            <div className="element">
              <div className="label">Total staked</div>
              <div className="value">{formatBalance(total_staked)}</div>
            </div>
            <div className="element">
              <div className="label">{`Balance (${staked_percent}%)`}</div>
              <div className="value withmax">
                <span className="max" onClick={()=>setStakeValue(idz.xidz)}>Max</span>
                <span>{formatBalance(idz.xidz)}</span>
              </div>
            </div>
            <div className="element">
              <div className="label">Rewards (IDZ)</div>
              <div className="value">{formatBalance(xidz_idz_value)}</div>
            </div>
            <div className="element">
              <div className="label">Balance (QS LP)</div>
              <div className="value withmax">
                <span className="max" onClick={()=>setStakeValue(idz.idzdex)}>Max</span>
                <span>{formatBalance(idz.idzdex)}</span>
              </div>
            </div>
            <div className="element">
              <div className="label">IDZ stake*</div>
              <div className={`value ${idz_stake_cost > 0 ? 'cost' : ''}`}>{formatBalance(idz_stake_cost)}</div>
            </div>
          </div>
        </div>
        <div className="stakeButtons">
          <button className={`${(can_stake && !loading) ? '' : 'disabled'} stakeButton`} onClick={handleStake}>Stake</button>
          <div className="stakeValue">
            <input type="number" value={stakeValue} onChange={e => setStakeValue(e.target.value)} />
          </div>
          <button className={`${(can_unstake && !loading) ? '' : 'disabled'} cancelButton`} onClick={handleUnstake}>Unstake</button>
        </div>
        <div className="readlink">
          <span className="nb">NB! The xFarm is experimental!</span><br/>
          Use are your own risk! Read more <a target="_blank" rel="noreferrer" href="https://blog.tezid.net/tezid-forever-farm-9edcbab30eda">here.</a><br/>
          * IDZ stake required - <a target="_blank" rel="noreferrer" href="https://blog.tezid.net/tezid-forever-farm-9edcbab30eda/#8da7">why?</a>
        </div>
      </div>
      <Footer />
    </div>
  )
}
