import { char2Bytes } from '@taquito/utils'
import { TezosToolkit } from '@taquito/taquito'
import { BeaconWallet } from '@taquito/beacon-wallet'
import { RequestSignPayloadInput, SigningType } from '@airgap/beacon-sdk'
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client'
import { Tzip16Module } from '@taquito/tzip16'
import { 
  TempleError
} from './utils'

const Tezos = new TezosToolkit()
Tezos.addExtension(new Tzip16Module())
const bwallet = new BeaconWallet({ name: "TezID" })
Tezos.setWalletProvider(bwallet)
let TezosDomainsClient = null

export async function setProvider(rpc) {
  Tezos.setProvider({ rpc: rpc}) 
}

export async function stake(network, staker, amount, setProcessingMessage) {
  const xfarm = await Tezos.wallet.at(network.xfarm_contract)
  const idz = await Tezos.wallet.at(network.idz_contract)
  const idzdex = await Tezos.wallet.at(network.idzdex_contract)
  if (setProcessingMessage) setProcessingMessage('Sending transaction...')
  const stakeAmount = Math.round(amount * 10**6)
  const batch = Tezos.wallet.batch()
    .withContractCall(idz.methods.update_operators([{
      add_operator: {
        owner: staker,
        operator: network.xfarm_contract,
        token_id: 0
      }
    }]))
    .withContractCall(idzdex.methods.update_operators([{
      add_operator: {
        owner: staker,
        operator: network.xfarm_contract,
        token_id: 0
      }
    }]))
    .withContractCall(xfarm.methods.stake(stakeAmount))
  if (setProcessingMessage) setProcessingMessage('Waiting for confirmation...')
  const op = await batch.send()
  await op.confirmation(1)
  return op.opHash
}

export async function unstake(network, staker, amount, setProcessingMessage) {
  const xfarm = await Tezos.wallet.at(network.xfarm_contract)
  const idz = await Tezos.wallet.at(network.idz_contract)
  const idzdex = await Tezos.wallet.at(network.idzdex_contract)
  if (setProcessingMessage) setProcessingMessage('Sending transaction...')
  const stakeAmount = Math.round(amount * 10**6)
  // TODO: We do not need to add farm as operator for xidz to burn xidz.
  // ^ This is because farm is already admin on the xidz token. 
  const batch = Tezos.wallet.batch()
    .withContractCall(idz.methods.update_operators([{
      remove_operator: {
        owner: staker,
        operator: network.xfarm_contract,
        token_id: 0
      }
    }]))
    .withContractCall(idzdex.methods.update_operators([{
      remove_operator: {
        owner: staker,
        operator: network.xfarm_contract,
        token_id: 0
      }
    }]))
    .withContractCall(xfarm.methods.exit(stakeAmount))
  if (setProcessingMessage) setProcessingMessage('Waiting for confirmation...')
  const op = await batch.send()
  await op.confirmation(1)
  return op.opHash
}

export async function removeAddress(network, address, cost, setProcessingMessage) {
  let contract = await Tezos.wallet.at(network.contract)
  if (setProcessingMessage) setProcessingMessage('Sending transaction...')
  let op = await contract.methods.removeIdentity(address).send({ amount: cost })
  if (setProcessingMessage) setProcessingMessage('Waiting for confirmation...')
  await op.confirmation(1)
  return op.opHash
}

export async function registerProof(network, address, proofType, cost, setProcessingMessage) {
  let contract = await Tezos.wallet.at(network.contract)
  if (setProcessingMessage) setProcessingMessage('Sending transaction...')
  let op = await contract.methods.registerProof(proofType).send({ amount: cost })
  if (setProcessingMessage) setProcessingMessage('Waiting for confirmation...')
  await op.confirmation(1)
  return op.opHash
}

export async function enableKYC(network, setProcessingMessage) {
  let contract = await Tezos.wallet.at(network.contract)
  if (setProcessingMessage) setProcessingMessage('Sending transaction...')
  let op = await contract.methods.enableKYC(null).send()
  if (setProcessingMessage) setProcessingMessage('Waiting for confirmation...')
  await op.confirmation(1)
  return op.opHash
}

export async function enableKYCPlatform(network, platform, setProcessingMessage) {
  let contract = await Tezos.wallet.at(network.contract)
  if (setProcessingMessage) setProcessingMessage('Sending transaction...')
  let op = await contract.methods.enableKYCPlatform(platform).send()
  if (setProcessingMessage) setProcessingMessage('Waiting for confirmation...')
  await op.confirmation(1)
  return op.opHash
}

export async function getActiveAccount() {
  const activeAccount = await bwallet.client.getActiveAccount()
  if (!activeAccount) return null
  activeAccount.balance = await getBalance(activeAccount.address)
  return activeAccount
}

export async function connectWallet(network) {
  try {
    const account = await bwallet.client.requestPermissions({
      network: {
        type: network.type,
        rpcUrl: network.rpc
      }
    })
    account.balance = await getBalance(account.address)
    return account 
  } catch(e) {
    throw new TempleError(e.message)
  }
}

export async function disconnectWallet() {
  await bwallet.clearActiveAccount() 
}

export async function getBalance(addr) {
  try {
    let balance = await Tezos.tz.getBalance(addr)
    return Math.round(((balance.toJSON()/1000000) + Number.EPSILON) * 100) / 100
  } catch (e) {
    return 0
  }
}

export async function resolveTezosDomainsAddress(network_name, address) {
  if (!TezosDomainsClient) TezosDomainsClient = new TaquitoTezosDomainsClient({ tezos: Tezos, network: network_name, caching: { enabled: true } })
  const domain = await TezosDomainsClient.resolver.resolveAddressToName(address)
  return domain
}

export async function signAvatarMessage({ address }) {
  const formattedInput: string = [
    'Tezos Signed Message:',
    window.location.origin,
    new Date(),
    'I want to update my TezID avatar.',
  ].join(' ')
  const bytes = char2Bytes(formattedInput)
  const payloadBytes = `050100${char2Bytes(bytes.length.toString())}${bytes}`
  const payload: RequestSignPayloadInput = {
    signingType: SigningType.MICHELINE,
    payload: payloadBytes,
    sourceAddress: address,
  }
  const signedPayload = await bwallet.client.requestSignPayload(payload)
  return { 
    sig: signedPayload.signature,
    message: payloadBytes,
  }
}

export async function signMessage({ wallet, message }) {
  const formattedInput: string = [
    'Tezos Signed Message:',
    window.location.origin,
    new Date(),
    message, 
  ].join(' ')
  const bytes = char2Bytes(formattedInput)
  const payloadBytes = `050100${char2Bytes(bytes.length.toString())}${bytes}`
  const payload: RequestSignPayloadInput = {
    signingType: SigningType.MICHELINE,
    payload: payloadBytes,
    sourceAddress: wallet.address,
  }
  const signedPayload = await bwallet.client.requestSignPayload(payload)
  return { 
    pk: wallet.publicKey,
    sig: signedPayload.signature,
    message: payloadBytes,
  }
}
