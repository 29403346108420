import { React } from 'react'
import BasicProofStatus from './basic'
import 'react-toggle/style.css'
import './twitter.css'

// eslint-disable-next-line
export default (props) => {

  return (
    <BasicProofStatus {...props}>
      { props.proof &&
      <>
      <div className="statusElement">
        <label>Handle</label>
        <div className="value">{props?.proof?.meta?.handle}</div> 
      </div>
      </>
      }
    </BasicProofStatus>
  )
}
