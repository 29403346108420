import { confirmAlert as _confirmAlert } from 'react-confirm-alert'
import './confirmAlertOrig.css'
import './confirmAlert.css'

export async function confirmAlertAsync(opts) {
  return new Promise((resolve, reject) => {
    if (!opts.customUI) opts.customUI = (props) => {
      const buttons = props.buttons.map((b,i) => {
        return (
          <button key={b.label} className={b.classes} onClick={() => {
            props.onClose()
            resolve(b.res)
          }}>{b.label}</button>
        )
      })
      return (
        <div className="confirmAlert">
          <div className="title"><div>{props.title}</div></div>
          <div className="message">{opts.messageComponent}</div> 
          <div className="buttons">
            {buttons}
          </div> 
        </div>
      )
    }
    return _confirmAlert(opts)
  })
}

export function confirmAlert(opts) {
  if (!opts.customUI) opts.customUI = (props) => {
    const buttons = props.buttons.map((b,i) => {
      return (
        <button key={b.label} className={b.classes} onClick={() => {
          props.onClose()
          if (b.onClick) b.onClick()
        }}>{b.label}</button>
      )
    })
    return (
      <div className="confirmAlert">
        <div className="title"><div>{props.title}</div></div>
        <div className="message">{opts.messageComponent}</div> 
        <div className="buttons">
          {buttons}
        </div> 
      </div>
    )
  }
  return _confirmAlert(opts)
}
