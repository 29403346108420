import EmailProofStatus from './email'
import GovIDProofStatus from './gov'
import TwitterProofStatus from './twitter'
import BasicProofStatus from './basic'

export const getStatusComponent = (proof) => {
  if (!proof) return null
  if (proof.id === 'gov') return GovIDProofStatus
  if (proof.id === 'email') return EmailProofStatus
  if (proof.id === 'twitter') return TwitterProofStatus 
  return BasicProofStatus 
} 
