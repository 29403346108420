import { React, useLayoutEffect, useState } from 'react'
import { useStore } from 'react-hookstore'
import { nav, getPath } from 'tiny-react-router'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { 
  HiOutlineDocumentText,
  HiOutlineClipboardCopy 
} from 'react-icons/hi'
import { FiExternalLink } from 'react-icons/fi'
import {
  AiOutlineUser,
  AiOutlineDelete 
} from 'react-icons/ai'
import { GrNetwork } from 'react-icons/gr'
import { RiCopperCoinLine, RiTestTubeLine } from 'react-icons/ri'
import { 
  TezosWallet,
  TezosWalletMenuDisconnect 
} from 'tezos-wallet-component'
import 'tezos-wallet-component/dist/TezosWallet.css'
import formatNumber from 'format-number'
import {
  idzStoreDefault
} from '../state'
import { 
  connectWallet, 
  disconnectWallet
} from '../wallet'
import { 
  getIDZ,
  getProofs,
  getAvatar,
  getNotificationChannels
} from '../api'
import { 
  getNetworkInfo,
} from '../utils'
import { 
  IPFS_BASE_URI,
  TEZOS_NETWORKS
} from '../config'
import './header.css'

const formatBalance = formatNumber({ round: 2})

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

// eslint-disable-next-line
export default (props) => {
  const [showWalletMenu, setShowWalletMenu] = useState(false)
  const [showCopyMessage, setShowCopyMessage] = useState(false)
  const [wallet, setWallet] = useStore('wallet')
  const [proofs, setProofs] = useStore('proofs')
  const [avatar, setAvatar] = useStore('avatar')
  const [network, setNetwork] = useStore('network')
  const [,setIDZ] = useStore('idz')
  const [,setNotificationChannels] = useStore('notificationchannels')
  const [width] = useWindowSize()
  const path = getPath()

  let networkInfo = getNetworkInfo(network) 

  const handleConnectWallet = async (network) => {
    try {
      setNetwork(network.name)
      const wallet = await connectWallet(network)
      setWallet(wallet)
      const proofs = await getProofs(wallet?.address)
      setProofs(proofs)
      const avatar = await getAvatar(wallet?.address)
      setAvatar(avatar)
      const idz = await getIDZ(wallet?.address)
      setIDZ(idz)
      const channels = await getNotificationChannels(wallet?.address)
      setNotificationChannels(channels)
      nav(`/${wallet?.address}`)
    } catch(e) {
      // TODO: Set AppState error?
      console.error(e)
    }
  }

  const handleDisconnectWallet = async () => {
    let confirmed = window.confirm('Disconnect Wallet?')
    if (!confirmed) return 
    await disconnectWallet()
    setWallet(null)
    setProofs([])
    setAvatar(null)
    setIDZ(idzStoreDefault)
    setShowWalletMenu(false)
    setNotificationChannels([])
  }

  const handleCopyClipboard = () => {
    setShowCopyMessage(true)
    setTimeout(() => {
      setShowCopyMessage(false)
    }, 1500)
  }

  const handleRemoveAddress = async () => {
    let confirm = window.confirm('Remove address and all associated proofs?')
    if (!confirm) return
    if (!props.handleRemoveAddress) return
    await props.handleRemoveAddress()
  }

  const handleOpenContract = () => {
     window.open(`${networkInfo.tzstats}/${networkInfo.contract}`, '_blank')
  }

  const handleOpenNetwork = () => {
     window.open(`${networkInfo.tzstats}`, '_blank')
  }

  const handleLogoClick = () => {
    nav('/')
  }

  return (
    <div className="header">
      <div className="logo" onClick={handleLogoClick}>
        <img src="logo-notext.svg" alt="logo" /> 
        <div className="logoSeparator"></div>
        <div className="logoLabels">
          <div className="logoLabel">TezID</div>
          <div className="logoLabelSmall">Identity oracle</div>
        </div>
      </div>
      <div className="menu">
        <div onClick={() => nav('/apps')} className="menuItem">
          Apps
        </div>
        <div onClick={() => nav('/idz')} className="menuItem">
          $IDZ
        </div>
        <div onClick={() => nav(`/${wallet?.address || 'gettingstarted'}`)} className="menuItem">
          TezID 
        </div>
      </div>
      <div className="walletSelector">
        { ['/',''].indexOf(path) >= 0 && !wallet?.address &&
          <div className="gettingStarted" onClick={() => nav(`/gettingstarted`)}>
            <div className="label">Get started!</div>
          </div>
        }
        { (['/',''].indexOf(path) < 0 || wallet?.address) &&
          <TezosWallet 
            address={wallet?.address} 
            balance={wallet?.balance}
            networks={Object.values(TEZOS_NETWORKS)}
            showMenu={showWalletMenu}
            ipfsBase={IPFS_BASE_URI}
            tezIDProfile={avatar}
            onToggleMenu={() => setShowWalletMenu(!showWalletMenu)}
            onConnectWallet={handleConnectWallet}
          >
            { width < 600 &&
              <>
              <div>
                <div className="label">{formatBalance(wallet?.balance)}</div>
                <div className="icon">ꜩ</div>
              </div>
              <div onClick={() => nav('/apps')}>
                <div className="label">Apps</div>
                <div className="icon"><HiOutlineDocumentText /></div>
              </div>
              <div onClick={() => nav('/idz')}>
                <div className="label">$IDZ</div>
                <div className="icon"><RiCopperCoinLine /></div>
              </div>
              <div onClick={() => nav(`/${wallet.address}`)}>
                <div className="label">TezID</div>
                <div className="icon"><AiOutlineUser /></div>
              </div>
              </>
            }
            <div onClick={handleOpenContract}>
              <div className="label">TezID Contract</div>
              <div className="icon"><FiExternalLink /></div>
            </div>
            <div onClick={handleOpenNetwork}>
              <div className="label">{networkInfo.label}</div>
              <div className="icon">
                { networkInfo.testnet &&
                  <RiTestTubeLine />
                }
                <GrNetwork />
              </div>
            </div>
            <div className="TezosWalletMenuCopyAddress">
              <CopyToClipboard text={wallet?.address} onCopy={handleCopyClipboard}>
              <div className="TezosWalletMenuCopyAddressInner">
                <div className="label">{showCopyMessage ? 'Copied!' : 'Copy address'}</div>
                <div className="icon"><HiOutlineClipboardCopy /></div>
              </div>
              </CopyToClipboard>
            </div>
            { proofs.length > 0 && window.location.hostname === 'localhost' &&
            <div className="TezosWalletMenuRemove" onClick={handleRemoveAddress}>
              <div className="label">Remove me from TezID</div>
              <div className="icon"><AiOutlineDelete /></div>
            </div>
            }
            <TezosWalletMenuDisconnect onClick={handleDisconnectWallet} />
          </TezosWallet>
        }
      </div>
    </div>
  )
}
