import { getNetworkInfo } from './utils'
import { networkStore } from './state'

export async function getProofs(address) {
  if (!address) return []
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/proofs/${address}`)
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function getAvatar(address) {
  if (!address) return null
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/avatar/${address}`)
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function setAvatar(address, payload) {
  if (!address) return null
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/avatar/${address}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' }
  })
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function getConfig() {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/config`)
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function getIDZ(address) {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/getidz/${address}`)
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function getProofTypes() {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/getprooftypes`)
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function getCode(address, prooftype, resource) {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/getcode`, {
    method: 'POST',
    body: JSON.stringify(Object.assign({
      address: address,
      prooftype: prooftype,
    }, resource)),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (!res.ok) throw new Error(await res.text())
  return await res.text()
}

export async function verifyTweet(address, prooftype, resource) {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/twitter/verify`, {
    method: 'POST',
    body: JSON.stringify(Object.assign({
      address: address,
      prooftype: prooftype,
    }, resource)),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (!res.ok) throw new Error(await res.text())
  return await res.text()
}

export async function getProcessingStatus(address, prooftypeid) {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/getprocessingstatus/${address}/${prooftypeid}`)
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function getPassbaseEncryptedMeta(address) {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/passbase/meta`,{
    method: 'POST',
    body: JSON.stringify({
      address: address,
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (!res.ok) throw new Error(await res.text())
  return await res.text()
}

export async function savePassbaseRequest(address, identityAccessKey, kyc) {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/passbase/request`,{
    method: 'POST',
    body: JSON.stringify({
      address: address,
      identityAccessKey: identityAccessKey,
      kyc: kyc
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (!res.ok) throw new Error(await res.text())
  return await res.text()
}

export async function verifyCode(address, prooftype, resource, code) {
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/verify`, {
    method: 'POST',
    body: JSON.stringify(Object.assign({
      address: address,
      prooftype: prooftype,
      code: code
    }, resource)),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function pinBlob(file, filename) {
  const network = getNetworkInfo(networkStore.getState()) 
  const formData = new FormData()
  formData.append('file', file, filename)
  const pinFileRes = await fetch(`/api/${network.name}/pin`, {
    method: 'POST',
    body: formData
  })
  if (!pinFileRes.ok) throw new Error('Unable to pin image to ipfs.')
  const pinFile = await pinFileRes.json()
  return pinFile
}

export async function getNotificationChannels(address) {
  if (!address) return null
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/notifications/${address}`)
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function createNotificationChannel(address, payload) {
  if (!address) return null
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/notifications/${address}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' }
  })
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}

export async function deleteNotificationChannel(address, payload) {
  if (!address) return null
  let network = getNetworkInfo(networkStore.getState()) 
  let res = await fetch(`/api/${network.name}/notifications/${address}`, {
    method: 'DELETE',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' }
  })
  if (!res.ok) throw new Error(await res.text())
  return await res.json()
}
