import { React } from 'react'
import './Processing.css'

// eslint-disable-next-line
export default (props) => {
  return (
    <>
    <div className="status">
      <div className="Processing">
        <div className={`processBox ${props.type}`}>
          <div className={`processingLoader ${props.type}`}>
            <img src={props.type === 'error' ? 'logo-gray-notext.svg' : 'logo-notext.svg'} alt="loader" />
          </div>
          <div className="processingMessage">{props.message}</div>
        </div>
      </div>
    </div>
    <div className="buttons processingButtons">
      <button className="cancel" disabled={!props.processingError} onClick={props.close}>Cancel</button>
    </div>
    </>
  )
}
