import { React } from 'react'
import Header from '../../shared/components/header'
import Footer from '../../shared/components/footer'
import './index.css' 

//        <div className="message">{props.message}</div>

// eslint-disable-next-line
export default (props) => {
  return (
    <div className="Preparing">
      <Header />
      <div className="content">
        <img className="loader" src="logo-notext.svg" alt="TezID Loader" />
      </div>
      <Footer />
    </div>
  )
}
