import TwitterCollector from './twitter'
import WorldIDCollector from './worldid'
import EmailCollector from './email'
import PhoneCollector from './phone'
import GovIDCollector from './gov'

export const getCollectorComponent = (proofType) => {
  if (proofType.id === 'worldid') return WorldIDCollector 
  if (proofType.id === 'twitter') return TwitterCollector
  if (proofType.id === 'email') return EmailCollector
  if (proofType.id === 'phone') return PhoneCollector
  if (proofType.id === 'gov') return GovIDCollector
  return null
} 
