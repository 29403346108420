import { createStore } from 'react-hookstore'
import { TEZOS_NETWORK_DEFAULT } from './config'
import { sleep, getNetworkInfo } from './utils'
import {
  setProvider,
  getActiveAccount
} from './wallet'
import { 
  getIDZ,
  getAvatar,
  getConfig,
  getProofs,
  getProofTypes,
  getNotificationChannels
} from './api'

export const idzStoreDefault = {
  idz: 0,
  xidz: 0,
  idzdex: 0,
  farm: {}
}
export const walletStore = createStore('wallet', null)
export const configStore = createStore('config', {})
export const idzStore = createStore('idz', idzStoreDefault)
export const proofStore = createStore('proofs', [])
export const avatarStore = createStore('avatar', null)
export const proofTypeStore = createStore('prooftypes', [])
export const appStateStore = createStore('appstate', 'loading')
export const appErrorStore = createStore('apperror', null)
export const networkStore = createStore('network', localStorage.getItem('teznet') || TEZOS_NETWORK_DEFAULT)
export const passbaseStore = createStore('passbaseKey', localStorage.getItem('passbaseKey') || null)
export const notificationChannelStore = createStore('notificationchannels', [])
export const codeResponseStore = createStore('coderesponse','')

export function clearLocalStorage() {
  localStorage.removeItem('teznet')
}

// init

async function initNetwork(teznet) {
  try {
    await sleep(500)
    walletStore.setState(null)
    const network = getNetworkInfo(teznet)
    localStorage.setItem('teznet', teznet)
    await setProvider(network.rpc) 

    // Config 

    const config = await getConfig()
    configStore.setState(config)

    // Wallet

    await sleep(100)
    const wallet = await getActiveAccount() 
    walletStore.setState(wallet)

    // Proofs

    await sleep(100)
    if (wallet?.address) {
      const proofs = await getProofs(wallet.address)
      proofStore.setState(proofs)
    }

    // AVATAR

    if (wallet?.address) {
      const avatar = await getAvatar(wallet.address)
      avatarStore.setState(avatar)      
    }

    // IDZ

    if (wallet?.address) {
      const idz = await getIDZ(wallet.address)
      idzStore.setState(idz)
    }

    // NOTIFICATIONCHANNELS 

    if (wallet?.address) {
      const channels = await getNotificationChannels(wallet.address)
      notificationChannelStore.setState(channels)      
    }

    // ProofTypes

    const proofTypes = await getProofTypes()
    proofTypeStore.setState(proofTypes || [])

    await sleep(1000)
    appStateStore.setState('ready')
  } catch(e) {
    appStateStore.setState('error')
    appErrorStore.setState(e)
    if (e.message.indexOf('Unsupported Tezos Network') >= 0) {
      clearLocalStorage()
      window.location.reload()
    }
  }
}

(async () => {
  networkStore.subscribe(initNetwork)
  await initNetwork(networkStore.getState())
})()
