import { Router } from 'tiny-react-router'
import { useStore } from 'react-hookstore'
import Error from './screens/Error'
import Preparing from './screens/Preparing'
//import Summary from './screens/Summary'
import Profile from './screens/Profile'
import Promo from './screens/Promo'
import Apps from './screens/Apps'
import Idz from './screens/Idz'
import './shared/state'
import './index.css'

// Handle id_token hash if present...
if (window.location.hash.indexOf('id_token') >= 0) {
  let str = window.location.hash
  let qs = str.replace('#id_token=', '?id_token=')
  // TODO: Should support localhost for testing?
  window.location.href = `https://tezid.net/api/mainnet/worldid/verify${qs}`
}

const routes = {
  '/'       : Promo,
  '/:addr'  : Profile, 
  '/apps'   : Apps, 
  '/idz'    : Idz,
}

export default function App () {
  const [appstate] = useStore('appstate')
  const [apperror] = useStore('apperror')
  let main = <Router routes={routes} />
  if (apperror) main = <Error error={apperror} />
  if (appstate === 'loading') main = <Preparing message={appstate.prepareMessage} />
  return (
    <div id="App">
      {main}
    </div>
  )
} 
