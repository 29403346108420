import { 
  React, 
  useRef, 
} from 'react'
import './code.css'

// eslint-disable-next-line
export default (props) => {
  const codeInput = useRef(null)

  const handleSubmit = () => {
    props.verifyCode(codeInput.current.value)
  }

  return (
    <>
    <div className="status resourceCollectorCode">
      <div className="inner">
        <div className="form">
          <input ref={codeInput} type="text" placeholder="Secret code" />
        </div>
      </div>
    </div>
    <div className="buttons">
      <button className="cancel" onClick={props.cancel}>Cancel</button>
      <div style={{ flex: 'auto' }} />
      <button className="submit" onClick={handleSubmit}>Verify</button>
    </div>
    </>
  )
}
