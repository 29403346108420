import { 
  React, 
  useState,
  useEffect 
} from 'react'
import { 
  verifyProofInCache,
  getTezCost,
  sleep 
} from '../../../shared/utils'
import { 
  getCode,
  verifyCode,
  verifyTweet,
  getProcessingStatus,
  createNotificationChannel,
  deleteNotificationChannel
} from '../../../shared/api'
import { 
  enableKYC,
  signMessage,
  registerProof,
  enableKYCPlatform,
} from '../../../shared/wallet'
import { confirmAlertAsync } from '../../../shared/components/confirmAlert'
import Processing from './Processing'
import { getCollectorComponent } from './collectors'
import { getStatusComponent } from './status'
import './ProofDetails.css'

// eslint-disable-next-line
export default (props) => {
  const [processing, setProcessing] = useState(false)
  const [processingType, setProcessingType] = useState('info')
  const [processingError, setProcessingError] = useState(false)
  const [processingMessage, setProcessingMessage] = useState('')
  const [collectingResources, setCollectingResources] = useState(false)
  const [collectedResource, setCollectedResource] = useState(null)
  const [collectingCode, setCollectingCode] = useState(false)
  const [collectingNotificationInfo, setCollectingNotificationInfo] = useState(false)
  const [selectedNotificationsPlatform, setSelectedNotificationsPlatform] = useState(null)
  const [requirementText, setRequirementText] = useState('')
  const [headerText, setHeaderText] = useState('')
  const [serverProcessing, setServerProcessing] = useState(false)
  const [serverProcessingStatus, setServerProcessingStatus] = useState('')

  useEffect(() => {
    const _checkProcessingStatus = async () => {
      if (!props.proof) return
      if (!props?.wallet?.address) return
      try {
        const _status = await getProcessingStatus(props?.wallet?.address, props.proofType.id)
        setServerProcessing(_status.processing)
        setServerProcessingStatus(_status.status)
      } catch(e) {
        console.error(e)
      }
    }
    _checkProcessingStatus()
    const interval = setInterval(_checkProcessingStatus, 2000)
    return () => clearInterval(interval)
  }, [props, serverProcessing, serverProcessingStatus])

  const ResourceCollector = getCollectorComponent(props.proofType)
  const _requirementText = requirementText !== '' ? requirementText : props.proofType.requirementText
  const _headerText = headerText !== '' ? headerText : props.proofType.label

  const checkProcessingStatus = async () => {
    if (props?.proof?.verified) return
    const _status = await getProcessingStatus(props.wallet.address, props.proofType.id)
    setServerProcessing(_status.processing)
    setServerProcessingStatus(_status.status)
  }

  const handleRegisterProof = async () => {
    if (!props.wallet) return
    if (!props.hasAnyProofs) {
      let accepted = await confirmAlertAsync({
        title: 'Terms',
        messageComponent: (
          <div>
            Since this appears to be the first time you are registering a proof with TezID, you need to accept our <a target="_blank" rel="noreferrer" href="terms.pdf">terms</a> and <a target="_blank" rel="noreferrer" href="privacy-policy.pdf">privacy policy</a> before proceeding.
            <br/><br/>
            Do you accept?
          </div>
        ),
        buttons: [
          {
            res: false,
            label: 'No',
            classes: 'cancel'
          },
          {
            res: true,
            label: 'Yes',
            classes: 'submit'
          }
        ],
        closeOnEscape: true
      })
      if(!accepted) return
    }
    try {
      setProcessing(true)
      setProcessingType('info')
      setProcessingError(false)
      setProcessingMessage(`Registering proof request on chain...`)
      await registerProof(props.networkInfo, props.wallet.address, props.proofType.id, getTezCost(props.cost), setProcessingMessage)
      const verifier = (proofs) => (proofs.filter(p => p.id === props.proofType.id)[0] || { verified: true }).verified === false
      const verified = await verifyProofInCache(props.wallet.address, verifier)
      if (!verified) throw new Error('Unable to verify operation')
      setProcessingMessage('Success!')
      await sleep(3000)
      await props.handleRefetchStorage()
      setProcessing(false)
      setProcessingMessage('')
    } catch(e) {
      setProcessing(false)
      setProcessingType('error')
      setProcessingError(true)
      setProcessingMessage(e.message)
    }
  }

  const handleGetCode = async (resource) => {
    try {
      setCollectedResource(resource)
      setProcessing(true)
      setProcessingType('info')
      setProcessingError(false)
      setProcessingMessage(`Requesting secret from oracle...`)
      const res = await getCode(props.wallet.address, props.proofType.id, resource)
      setProcessingMessage('Success!')
      await sleep(3000)
      setProcessing(false)
      setProcessingMessage('')
      setCollectingCode(true)
      return res
    } catch(e) {
      setProcessing(false)
      setProcessingType('error')
      setProcessingError(true)
      setProcessingMessage(e.message)
    }
  }

  const handleVerifyCode = async (code) => {
    try {
      setProcessing(true)
      setProcessingType('info')
      setProcessingError(false)
      setProcessingMessage(`Requesting verification from oracle...`)
      await verifyCode(props.wallet.address, props.proofType.id, collectedResource, code)
      const verifier = (proofs) => (proofs.filter(p => p.id === props.proofType.id)[0] || { verified: false }).verified === true
      const verified = await verifyProofInCache(props.wallet.address, verifier)
      if (!verified) throw new Error('Unable to verify operation')
      setProcessingMessage('Success!')
      await sleep(3000)
      await props.handleRefetchStorage()
      setProcessing(false)
      setProcessingMessage('')
      setHeaderText('')
      setRequirementText('')
      setCollectingCode(false)
      setCollectingResources(false)
    } catch(e) {
      setProcessing(false)
      setProcessingType('error')
      setProcessingError(true)
      setProcessingMessage(e.message)
    }
  }

  const handleEnableKYC = async () => {
    try {
      setProcessing(true)
      setProcessingType('info')
      setProcessingError(false)
      setProcessingMessage(`Updating metadata on chain...`)
      await enableKYC(props.networkInfo, setProcessingMessage) 
      const verifier = (proofs) => {
        const govProof = proofs.filter(p => p.id === props.proofType.id)[0]
        if (!govProof) return false
        if (govProof?.meta?.kyc && govProof.meta.kyc === "true") return true
        return false
      } 
      const verified = await verifyProofInCache(props.wallet.address, verifier)
      if (!verified) throw new Error('Unable to verify operation')
      setProcessingMessage('Success!')
      await sleep(3000)
      await props.handleRefetchStorage()
      setProcessing(false)
      setProcessingMessage('')
      setHeaderText('')
      setRequirementText('')
      setCollectingCode(false)
      setCollectingResources(false)
    } catch(e) {
      setProcessing(false)
      setProcessingType('error')
      setProcessingError(true)
      setProcessingMessage(e.message)
    }
  }

  const handleEnableKYCPlatform = async (platform) => {
    try {
      setProcessing(true)
      setProcessingType('info')
      setProcessingError(false)
      setProcessingMessage(`Updating metadata on chain...`)
      await enableKYCPlatform(props.networkInfo, `kyc_${platform}`, setProcessingMessage) 
      const verifier = (proofs) => {
        const govProof = proofs.filter(p => p.id === props.proofType.id)[0]
        if (!govProof) return false
        const meta = govProof.meta || {}
        if (meta[`kyc_${platform}`] === "true") return true
        return false
      } 
      const verified = await verifyProofInCache(props.wallet.address, verifier)
      if (!verified) throw new Error('Unable to verify operation')
      setProcessingMessage('Success!')
      await sleep(3000)
      await props.handleRefetchStorage()
      setProcessing(false)
      setProcessingMessage('')
      setHeaderText('')
      setRequirementText('')
      setCollectingCode(false)
      setCollectingResources(false)
    } catch(e) {
      setProcessing(false)
      setProcessingType('error')
      setProcessingError(true)
      setProcessingMessage(e.message)
    }
  }

  const handleCollectingNotificationInfo = async (platform) => {
    setCollectingResources(true)
    setCollectingNotificationInfo(true) 
    setSelectedNotificationsPlatform(platform)
  }

  const handleEnableNotificationChannel = async (platform, target) => {
    const sig = await signMessage({ 
      wallet: props?.wallet, 
      message: `I hereby allow ${platform?.name} to send notifications to ${target}`
    })
    setProcessing(true)
    setProcessingMessage('Enabling notification channel...')
    const payload = { 
      signature: sig, 
      platform: platform, 
      target: target,
      address: props?.wallet?.address,
      prooftype: props?.proof?.id
    }
    payload[props?.proof?.id] = target
    try  {
      const channels = await createNotificationChannel(props?.wallet?.address, payload)
      props.setNotificationChannels(channels)
      setProcessingMessage('Success!')
      await sleep(2000)
      setProcessing(false)
      setProcessingMessage('')
      setCollectingResources(false)
      setCollectingNotificationInfo(false) 
      setSelectedNotificationsPlatform(null)
      setHeaderText('') // NOTE: Needs to do these last, or atleast after setCollectingResources = False if not it's set again by sub component
      setRequirementText('')
    } catch(e) {
      setProcessing(false)
      setProcessingType('error')
      setProcessingError(true)
      setProcessingMessage(e.message)
    }
  }

  const handleDisableNotificationChannel = async (platform) => {
    const sig = await signMessage({ 
      wallet: props?.wallet, 
      message: `I hereby disable ${platform?.name} notifications via ${props?.proof?.id}`
    })
    setProcessing(true)
    setProcessingMessage('Disabling notification channel...')
    const payload = { 
      signature: sig, 
      platform: platform, 
      prooftype: props?.proof?.id
    }
    try  {
      const channels = await deleteNotificationChannel(props?.wallet?.address, payload)
      props.setNotificationChannels(channels)
      setProcessingMessage('Success!')
      await sleep(2000)
      setProcessing(false)
      setProcessingMessage('')
    } catch(e) {
      setProcessing(false)
      setProcessingType('error')
      setProcessingError(true)
      setProcessingMessage(e.message)
    }
  }

  const handleVerifyTweet = async (tweet) => {
    const handle = tweet.split('twitter handle ')[1].split(' ')[0]
    const code = tweet.split('@tezid_net ')[1]
    try {
      setProcessing(true)
      setProcessingType('info')
      setProcessingError(false)
      setProcessingMessage(`Verifying tweet...`)
      await verifyTweet(props?.wallet?.address, 'twitter', {
        handle: handle,
        code: code 
      })
      setProcessingMessage('Success!')
      await sleep(3000)
      await props.handleRefetchStorage()
      setProcessing(false)
      setProcessingMessage('')
      setHeaderText('')
      setRequirementText('')
      setCollectingCode(false)
      setCollectingResources(false)
    } catch(e) {
      setProcessing(false)
      setProcessingType('error')
      setProcessingError(true)
      setProcessingMessage(e.message)
    }
  }

  const cost = (props?.cost || 0) / 1000000

  const Buttons = () => {
    if (!props?.isMine) return null
    return (
      <div className="buttons">
        <button className="cancel" onClick={props.cancel} disabled={processing}>Cancel</button>
        { !props.proof && props.wallet &&
        <button className="submit" onClick={handleRegisterProof} disabled={processing}>{`Register - ${cost}ꜩ`}</button>
        }
        { props.proof && !props.proof.verified &&
        <button className="submit" onClick={() => setCollectingResources(true)} disabled={processing || serverProcessing}>Verify</button>
        }
        { props.proof && props.proof.verified &&
        <button className="submit" onClick={handleRegisterProof} disabled={processing || serverProcessing}>{`Renew - ${cost}ꜩ`}</button>
        }
      </div>
    )
  }

  const ProofStatus = getStatusComponent(props.proofType) 

  return (
    <div className="resourceDetailsContainer">
      <div className="top">
        <div className="header">
          <h3>{_headerText}</h3>
        </div>
        <p>
          {_requirementText} 
        </p>
      </div>
      <div className="main">
        { (processing || processingError) &&
          <Processing 
            processingError={processingError}
            message={processingMessage} 
            close={() => {
              setProcessingError(false)
              setProcessingType('info')
              setProcessingMessage('')
            }} 
            type={processingType} 
          />
        }
        { !processing && !processingError && collectingResources &&
          <ResourceCollector 
            selectedNotificationsPlatform={selectedNotificationsPlatform}
            collectingNotificationInfo={collectingNotificationInfo}
            handleEnableNotificationChannel={handleEnableNotificationChannel}
            serverProcessingStatus={serverProcessingStatus}
            setRequirementText={setRequirementText}
            setCollectingCode={setCollectingCode}
            handleVerifyTweet={handleVerifyTweet}
            collectingCode={collectingCode}
            setHeaderText={setHeaderText}
            networkInfo={props.networkInfo}
            verifyCode={handleVerifyCode}
            getCode={handleGetCode}
            wallet={props.wallet}
            config={props.config}
            proof={props.proof}
            cancel={async () => {
              setHeaderText('')
              setRequirementText('')
              setCollectingResources(false)
              setCollectingNotificationInfo(false)
              await checkProcessingStatus()
            }} 
          /> 
        }
        { !processing && !processingError && !collectingResources &&
          <ProofStatus 
            proof={props.proof} 
            wallet={props.wallet}
            buttons={<Buttons />}
            proofType={props.proofType}
            enableKYC={handleEnableKYC}
            enableKYCPlatform={handleEnableKYCPlatform}
            serverProcessing={serverProcessing}
            notificationchannels={props.notificationchannels}
            serverProcessingStatus={serverProcessingStatus}
            collectingNotificationInfo={handleCollectingNotificationInfo}
            handleDisableNotificationChannel={handleDisableNotificationChannel}
          /> 
        }
      </div>
    </div>
  )
}
