import { 
  React,
} from 'react'
import { 
  differenceInDays
} from 'date-fns' 
import { 
  formatDate,
  capitalize,
  getProofStatus,
} from '../../../../shared/utils'
import { 
  RENEW_DAYS
} from '../../../../shared/config'
import './basic.css'

// eslint-disable-next-line
export default (props) => {
  let proofStatus = getProofStatus(props.proof)
  if (proofStatus !== 'Verified' && props.serverProcessingStatus !== '')
    proofStatus = capitalize(props.serverProcessingStatus) 

  const valid_days = RENEW_DAYS - differenceInDays(new Date(), new Date(props?.proof?.register_date))

  return (
    <>
    <div className="status">
      <div className="statusElement">
        <label>Status</label>
        <div className={`${props.serverProcessingStatus} value`}>{proofStatus}</div>
      </div>
      <div className="statusElement">
        <label>Register date</label>
        <div className="value">{formatDate(props?.proof?.register_date) || '-'}</div>
      </div>
      <div className="statusElement">
        <label>Expired</label>
        <div className={`${props?.proof?.expired ? 'expired' : ''} value`}>{`${props?.proof ? props?.proof?.expired ? 'Yes' : 'No' : '-'}`}</div>
      </div>
      <div className="statusElement">
        <label>Expires in (days)</label>
        <div className={`${props?.proof?.expired ? 'expired' : ''} value`}>{valid_days || '-'}</div>
      </div>
      {props.children}
    </div>
    {props.buttons}
    </>
  )
}
