import { 
  React, 
  useState, 
  useEffect 
} from 'react'
import PhoneInput, { 
  isValidPhoneNumber 
} from 'react-phone-number-input'
import CollectCode from './code'
import 'react-phone-number-input/style.css'
import './phone.css'

// eslint-disable-next-line
export default (props) => {
  const [number, setNumber] = useState('')

  const handleSubmit = () => {
    if (!isValidPhoneNumber(number)) return
    props.getCode({ phonenumber: number })
  }

  useEffect(() => {
    if (props.collectingCode) {
      props.setHeaderText('Verify number') 
      props.setRequirementText('Enter the secret code sent to your phone. If you did not receive a secret code, please try again or contact support@tezid.net!')
    }
    else { 
      props.setHeaderText('Register number') 
      props.setRequirementText('Enter your mobile phone number. The oracle will send you a secret code on SMS.')
    }
  }, [props])

  if (props.collectingCode) return (
    <CollectCode 
      cancel={() => props.setCollectingCode(false)} 
      verifyCode={props.verifyCode}
      setRequirementText={props.setRequirementText} 
    />
  )

  return (
    <>
    <div className="status resourceCollectorPhone">
      <div className="inner">
        <div className="form">
          <PhoneInput
            placeholder="Enter phone number"
            value={number}
            onChange={setNumber}
          />
        </div>
      </div>
    </div>
    <div className="buttons">
      <button className="cancel" onClick={props.cancel}>Cancel</button>
      <div style={{ flex: 'auto' }} />
      <button className="submit" onClick={handleSubmit}>Get code</button>
    </div>
    </>
  )
}
