import { 
  React, 
  useRef, 
  useState, 
  useEffect 
} from 'react'
import CollectCode from './code'
import EmailValidator from 'email-validator'
import './email.css'

// eslint-disable-next-line
export default (props) => {
  const [error, setError] = useState(false) 
  const emailInput = useRef(null)

  const handleGetCode = () => {
    let email = emailInput.current.value
    let valid = EmailValidator.validate(email)
    if (!valid) return setError(true) 
    props.getCode({ email: email })
  }

  const handleRegisterForNotifications = async () => {
    let email = emailInput.current.value
    let valid = EmailValidator.validate(email)
    if (!valid) return setError(true) 
    try {
      await props.handleEnableNotificationChannel(props.selectedNotificationsPlatform, email)
    } catch(e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (props.collectingCode) {
      props.setHeaderText('Verify email') 
      props.setRequirementText('Enter the secret code sent to your email address. If you did not receive a secret code, please try again or contact support@tezid.net!')
    } else if (props.collectingNotificationInfo) {
      props.setHeaderText(`Enable notification channel`) 
      props.setRequirementText(`This action allows ${props.selectedNotificationsPlatform.name} to send you email via a TezID notification channel. We do not share your email address with ${props.selectedNotificationsPlatform.name}. Enter the email address you used to register your email proof.`)
    }
    else { 
      props.setHeaderText('Register email') 
      props.setRequirementText('Enter your email address. The oracle will send you a secret code.')
    }
  }, [props])

  if (props.collectingCode) return (
    <CollectCode 
      cancel={() => props.setCollectingCode(false)} 
      verifyCode={props.verifyCode}
      setRequirementText={props.setRequirementText} 
    />
  )

  return (
    <>
    <div className="status resourceCollectorEmail">
      <div className="inner">
        <div className="form">
          <input className={`${error ? 'error' : ''}`} ref={emailInput} type="email" placeholder="Email address" onChange={() => setError(false)} />
        </div>
      </div>
    </div>
    <div className="buttons">
      <button className="cancel" onClick={props.cancel}>Cancel</button>
      <div style={{ flex: 'auto' }} />
      { props.collectingNotificationInfo &&
        <button className="submit" onClick={handleRegisterForNotifications}>Allow</button>
      }
      { !props.collectingNotificationInfo &&
        <button className="submit" onClick={handleGetCode}>Get code</button>
      }
    </div>
    </>
  )
}
