import { React } from 'react'
import { GoVerified, GoUnverified } from 'react-icons/go'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import BasicProofStatus from './basic'
import { confirmAlert, confirmAlertAsync } from '../../../../shared/components/confirmAlert'
import { TEZID_APPS } from '../../../../shared/config'
import 'react-toggle/style.css'
import './email.css'

// eslint-disable-next-line
export default (props) => {

  const handleNextPlatform = async (platform) => {
    confirmAlert({
      title: 'Our next platform?',
      messageComponent: (
        <div>
          Are looking to do email notificaitons on Tezos? Look no further! Reach out via <a href="https://t.me/tezid" target="_blank" rel="noreferrer">telegram</a>, <a href="https://twitter.com/tezid_net" target="_blank" rel="noreferrer">twitter</a> or <a href="mailto:support@tezid.net">email</a> and sign up as a TezID Platform today!
        </div>
      ),
      buttons: [
        {
          label: 'OK',
          classes: 'submit'
        }
      ],
      closeOnEscape: true
    })
  }

  const handlePlatformClick = async (p, enabled) => {
    if (!enabled) {
      if (!props?.proof?.verified) return confirmAlert({
        title: 'Unverified proof',
        messageComponent: (
          <div>To enable this notification channel you need a verified email proof.</div>
        ),
        buttons: [
          {
            label: 'OK',
            classes: 'submit'
          }
        ],
        closeOnEscape: true
      })
      return props.collectingNotificationInfo(p)
    }
    const allow = await confirmAlertAsync({
      title: 'Disable notification channel?',
      messageComponent: (
        <div>
          This action will stop {`${p.name}`} from sending any emails to you in the future.<br/><br/> Are you sure?
        </div>
      ),
      buttons: [
        {
          res: false,
          label: 'No',
          classes: 'cancel'
        },
        {
          res: true,
          label: 'Yes',
          classes: 'submit'
        }
      ],
      closeOnEscape: true
    })
    if (!allow) return
    try {
      await props.handleDisableNotificationChannel(p) 
    } catch(e) { 
      console.error(e)
    }
  }

  const platformItems = TEZID_APPS.filter(a => a.email).map(p => {
    const enabled = props.notificationchannels.filter(channel => channel.platform === p.id).length > 0 
    return (
      <div key={p.id} className={`${enabled ? 'enabled' : 'disabled'} platformItem`} onClick={() => handlePlatformClick(p, enabled)}>
        { enabled &&
          <div className="mark">
            <GoVerified />
          </div>
        }
        { !enabled &&
          <div className="mark">
            <GoUnverified />
          </div>
        }
        <img alt={p.id} src={p.icon} />
      </div>
    )
  })

  return (
    <BasicProofStatus {...props}>
      { props.proof &&
      <>
      <div className="statusElement">
        <div className="platforms">
          {platformItems}
          <div className="disabled platformItem nextPlatform" onClick={handleNextPlatform}>
            <AiOutlineQuestionCircle size="2.8em" />
          </div>
        </div>
      </div>
      </>
      }
    </BasicProofStatus>
  )
}
