import React from 'react'
import { AiFillGithub, AiFillTwitterCircle } from 'react-icons/ai'
import './footer.css'

// eslint-disable-next-line
export default (props) => {
  return (
    <div className="footer">
      <div className="infoLinks">
        <a target="_blank" rel="noreferrer" href="https://blog.tezid.net/tezid-9d82efbba006">About</a>
        <a target="_blank" rel="noreferrer" href="https://github.com/tezid/docs/blob/main/LEGAL.md">Legal</a>
        <a target="_blank" rel="noreferrer" href="https://blog.tezid.net/">Blog</a>
        <a target="_blank" rel="noreferrer" href="https://t.me/tezid">Telegram</a>
      </div>
      <div className="spacer" />
      <div className="resourceLinks">
        <a target="_blank" rel="noreferrer" href="https://github.com/tezid"><AiFillGithub size="2em" /></a>
        <a target="_blank" rel="noreferrer" href="https://twitter.com/tezid_net"><AiFillTwitterCircle size="2em" /></a>
      </div>
    </div>
  )
}
