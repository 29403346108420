import { 
  addDays, 
  format,
  differenceInDays 
} from 'date-fns'
import { getProofs, getIDZ } from './api'
import { 
  RENEW_DAYS,
  IPFS_BASE_URI,
  TEZOS_NETWORKS,
} from './config'

export async function verifyProofInCache(address, verifier, maxRetries=10, sleepTime=20000) {
  let resolved = false 
  for (let retry = 0; retry <= maxRetries; retry++) {
    const proofs = await getProofs(address)
    if (verifier(proofs)) {
      resolved = true
      retry = maxRetries
    }
    await sleep(sleepTime)
  }
  return resolved
}

export async function verifyStake(address, verifier, maxRetries=10, sleepTime=20000) {
  let resolved = false 
  for (let retry = 0; retry <= maxRetries; retry++) {
    const idz = await getIDZ(address)
    if (verifier(idz)) {
      resolved = true
      retry = maxRetries
    }
    await sleep(sleepTime)
  }
  return resolved
}

export function getNetworkInfo(network) {
  if (Object.keys(TEZOS_NETWORKS).indexOf(network) < 0)
      throw new Error(`Unsupported Tezos Network: ${network}`)
  return TEZOS_NETWORKS[network]
}

export function getProofTypeCost(cost, prooftype) {
  if (!cost) return 0
  if (cost[prooftype] !== undefined) return cost[prooftype]
  else return cost['default']
}

export function getTezCost(cost) {
  if (!cost) return 0
  return Math.round(((cost/1000000) + Number.EPSILON) * 100) / 100
}

export function formatDate(date, options={}) {
  if (!options.format) options.format = 'dd-MM-yyyy'
  if (!options.noDateValue) options.noDateValue = ''
  try {
    date = new Date(date)
    if (options.addDays) date = addDays(date, options.addDays)
    date = format(date, options.format)
    return date
  } catch(e) {
    return options.noDateValue
  }
}

export function capitalize(string, preserve) {
  if (!preserve) {
    string = string.toLowerCase();
  }
  return string.charAt(0).toUpperCase() + string.substring(1)
}

export function isProofExpired(proof) {
  if (!proof) return false
  const diff = Math.abs(differenceInDays(new Date(), new Date(proof.register_date)))
  if (diff >= RENEW_DAYS) return true
  return false
}

export function getProofStatus(proof) {
  if (!proof) return 'Not found'
  if (!proof.verified) return 'Unverified'
  return 'Verified'
}

export function getShieldComponent(proof) {
  let shield = proof != null ? proof.verified ? <img src="logo-notext.svg" alt="Verified" /> : <img src="logo-mono-notext.svg" alt="Unverified" /> : <img src="logo-gray-notext.svg" alt="No proof" />
  return shield
}

export function getIpfsLink(ipfsPath) {
  return `${IPFS_BASE_URI}/${ipfsPath?.split('ipfs://')[1]}`
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export class TempleError extends Error {}
