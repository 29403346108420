import { 
  React, 
  useRef, 
  useState, 
  useEffect 
} from 'react'
import { useStore } from 'react-hookstore'
import './twitter.css'

// eslint-disable-next-line
export default (props) => {
  const [error, setError] = useState(false) 
  const [twitterMessage, setTwitterMessage] = useStore('coderesponse')
  const twitterInput = useRef(null)

  const handleGetCode = async () => {
    let handle = twitterInput.current.value
    let valid = true
    if (handle === '') valid = false 
    if (handle[0] !== '@') valid = false 
    if (!valid) return setError(true) 
    const res = await props.getCode({ handle: handle })
    setTwitterMessage(res)
  }

  const handleVerifyTweet = async () => {
    await props.handleVerifyTweet(twitterMessage)
  }

  useEffect(() => {
    if (props.collectingCode) {
      props.setHeaderText('Verify Twitter Handle') 
      props.setRequirementText('Tweet the below message and afterwards hit the "Verify tweet" button.')
    } else { 
      props.setHeaderText('Register Twitter Handle') 
      props.setRequirementText('Enter your Twitter handle. The oracle will send you a secret message to tweet.')
    }
  }, [props])

  if (props.collectingCode) return (
    <>
    <div className="twittermessage">
      {twitterMessage}
    </div>
    <div className="buttons">
      <button className="cancel" onClick={props.cancel}>Cancel</button>
      <div style={{ flex: 'auto' }} />
      <button className="submit" onClick={handleVerifyTweet}>Verify tweet</button>
    </div>
    </>
  )

  return (
    <>
    <div className="status resourceCollectorTwitter">
      <div className="inner">
        <div className="form">
          <input className={`${error ? 'error' : ''}`} ref={twitterInput} type="text" placeholder="@twitterhandle" onChange={() => setError(false)} />
        </div>
      </div>
    </div>
    <div className="buttons">
      <button className="cancel" onClick={props.cancel}>Cancel</button>
      <div style={{ flex: 'auto' }} />
      <button className="submit" onClick={handleGetCode}>Get tweet</button>
    </div>
    </>
  )
}
